<template>
  <div>
    <div data-aos="fade-up" data-aos-delay="300" data-aos-once="true" class="text-center my-3">
      RESULT
      <div>
        <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
          <h4
            data-aos="fade-up"
            data-aos-delay="350"
            data-aos-once="true"
            class="result-name py-2"
          >Result</h4>
          <button class="btn btn-transparent">
            <caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
            <caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
          </button>
        </div>
        <div class="relative text-left p-2" v-if="isCollapsed">
          <div class="flex my-2">
            <h6
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-once="true"
            >Weapon Count : {{weaponCount}}</h6>
          </div>
        </div>
      </div>

      <result-drop-down-section :title="'Request'" v-bind:receivedResult="requestMade" />

      <result-drop-down-section :title="'Response JSON'" v-bind:receivedResult="result" />
    </div>
  </div>
</template>

<script>
import ResultDropDownSection from "./ResultDropDownSection.vue";
import CaretDownIcon from "../../icons/CaretDownIcon";
import CaretUpIcon from "../../icons/CaretUpIcon.vue";

export default {
  components: {
    ResultDropDownSection,
    CaretDownIcon,
    CaretUpIcon
  },
  data() {
    return {
      isCollapsed: Boolean
    };
  },
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    }
  },

  props: {
    result: Array,
    isLoading: Boolean,
    requestMade: Array,
    weaponCount: Number
  }
};
</script>

<style>
</style>